/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

import { H3, H4, P, Strong } from '../components/Text';
import { Ul, Li } from '../components/List';
import { PrivacyPolicyLayout } from '../layouts/PrivacyPolicyLayout';

const PrivacyPolicyPage = () => {
  return (
    <PrivacyPolicyLayout>
      <Strong sx={{ fontWeight: 'bold' }}>Effective: 25 May 2018</Strong>
      <P>
        Interaction Design Association Inc (“us”, “we”, or “our”) operates the
        ixda.org website (the “Website”), and associated subdomains (the
        “Service”).
      </P>
      <P>
        We are committed to protecting the privacy of IxDA members and
        individuals who visit our Website and utilize the Service. This page
        informs you of our policies regarding the collection, use, and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data.
      </P>
      <P>
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy. Unless otherwise defined in this Privacy
        Policy, terms used in this Privacy Policy have the same meanings as in
        our Terms and Conditions, accessible from ixda.org
      </P>
      <H3>Definitions</H3>
      <H4>Personal Data</H4>
      <P>
        Personal Data means data about a living individual who can be identified
        from those data (or from those and other information either in our
        possession or likely to come into our possession).
      </P>
      <H4>Usage Data</H4>
      <P>
        Usage Data is data collected automatically either generated by the use
        of the Service or from the Service infrastructure itself (for example,
        the duration of a page visit).
      </P>
      <H4>Cookies</H4>
      <P>Cookies are small pieces of data stored on a User’s device.</P>
      <H4>Data Controller</H4>
      <P>
        Data Controller means a person who (either alone or jointly or in common
        with other persons) determines the purposes for which and the manner in
        which any personal data are, or are to be, processed.
      </P>
      <P>
        For the purpose of this Privacy Policy, we are a Data Controller of your
        data.
      </P>
      <H4>Data Processor (or Service Providers)</H4>
      <P>
        Data Processor (or Service Provider) means any person (other than an
        employee of the Data Controller) who processes the data on behalf of the
        Data Controller.
      </P>
      <P>
        We may use the services of various Service Providers in order to process
        your data more effectively.
      </P>
      <H4>Data Subject</H4>
      <P>
        Data Subject is any living individual who is the subject of Personal
        Data.
      </P>
      <H4>User</H4>
      <P>
        The User is the individual using our Service. The User corresponds to
        the Data Subject, who is the subject of Personal Data.
      </P>
      <H3>Information Collection And Use</H3>
      <P>
        We collect several different types of information for various purposes
        to provide and improve our Service to you. We comply with our legal
        obligations by keeping personal data up to date; by storing and
        destroying it securely; by not collecting or retaining excessive amounts
        of data; by protecting personal data from loss, misuse, unauthorized
        access and disclosure; and by ensuring that appropriate technical
        measures are in place to protect personal data.
      </P>
      <H3>Types of Data Collected</H3>
      <H4>Personal Data</H4>
      <P>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you (“Personal Data”). Personally identifiable information may
        include, but is not limited to:
      </P>
      <Ul>
        <Li>Email address</Li>
        <Li>First name and last name</Li>
        <Li>Company or institution name</Li>
        <Li>Postal address</Li>
        <Li>Cookies and Usage Data</Li>
      </Ul>
      <P>
        We may use your Personal Data to contact you with newsletters, marketing
        or promotional materials and other information that may be of interest
        to you. Our legal basis for doing so is your consent OR our legitimate
        interests, specifically the proper operation of our Services and
        organization. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or instructions
        provided in any email we send, or by contacting our Privacy Officer at
        the email address set forth in this Policy.
      </P>
      <H4>Usage Data</H4>
      <P>
        We may also collect information how the Service is accessed and used
        (“Usage Data”). This Usage Data may include information such as your
        computer’s Internet Protocol address (e.g. IP address), browser type,
        browser version, the pages of our Service that you visit, the time and
        date of your visit, the time spent on those pages, unique device
        identifiers and other diagnostic data. Our legal basis for doing so is
        your consent OR our legitimate interests, specifically the proper
        operation of our Services and organization.
      </P>
      <H4>Location Data</H4>
      <P>
        We may use and store information about your location if you give us
        permission to do so (“Location Data”). We use this data to provide
        features of our Service, to improve and customize our Service. Our legal
        basis for doing so is your consent OR our legitimate interests,
        specifically the proper operation of our Services and organization.
      </P>
      <P>
        You can enable or disable location services when you use our Service at
        any time, through your device settings.
      </P>
      <H4>Tracking and Cookies Data</H4>
      <P>
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information.
      </P>
      <P>
        Cookies are files with small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. They are used to ‘remember’ when your
        computer or device accesses our websites. Tracking technologies also
        used are beacons, tags, and scripts to collect and track information and
        to improve and analyze our Service.
      </P>
      <P>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </P>
      <P>Examples of Cookies we use:</P>
      <Ul>
        <Li>Session Cookies. We use Session Cookies to operate our Service.</Li>
        <Li>
          Preference Cookies. We use Preference Cookies to remember your
          preferences and various settings.
        </Li>
        <Li>
          Security Cookies. We use Security Cookies for security purposes.
        </Li>
      </Ul>
      <H4>Use of Data</H4>
      <P>
        Interaction Design Association Inc uses the collected data for various
        purposes:
      </P>
      <Ul>
        <Li>To provide and maintain our Service</Li>
        <Li>To notify you about changes to our Service</Li>
        <Li>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </Li>
        <Li>To provide customer support</Li>
        <Li>
          To gather analysis or valuable information so that we can improve our
          Service
        </Li>
        <Li>To monitor the usage of our Service</Li>
        <Li>To detect, prevent and address technical issues</Li>
        <Li>
          To provide you with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless you
          have opted not to receive such information
        </Li>
      </Ul>
      <H4>Security Of Data</H4>
      <P>
        The security of your data is important to us, but remember that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security.
      </P>
      <H4>“Do Not Track” Signals</H4>
      <P>
        We do not support Do Not Track (“DNT”). Do Not Track is a preference you
        can set in your web browser to inform websites that you do not want to
        be tracked.
      </P>
      <P>
        You can enable or disable Do Not Track by visiting the Preferences or
        Settings page of your web browser.
      </P>
      <H3>Your Rights</H3>
      <P>
        Interaction Design Association Inc aims to take reasonable steps to
        allow you to correct, amend, delete, or limit the use of your Personal
        Data.
      </P>
      <P>
        Whenever made possible, you can update your Personal Data directly
        within your account settings section. If you are unable to change your
        Personal Data, please contact us to make the required changes. Email
        your request to our data protection officer at{' '}
        <Link
          to="mailto:privacypolicy@ixda.org"
          sx={{ variant: 'variants.link' }}
        >
          privacypolicy@ixda.org
        </Link>
      </P>
      <P>
        If you wish to be informed what Personal Data we hold about you and if
        you want it to be removed from our systems, please contact us.
      </P>
      <P>In certain circumstances, you have the right:</P>
      <Ul>
        <Li>
          To access and receive a copy of the Personal Data we hold about you
        </Li>
        <Li>To rectify any Personal Data held about you that is inaccurate</Li>
        <Li>To request the deletion of Personal Data held about you</Li>
      </Ul>
      <P>
        You have the right to data portability for the information you provide
        to Interaction Design Association Inc. You can request to obtain a copy
        of your Personal Data in a commonly used electronic format so that you
        can manage and move it.
      </P>
      <P>
        Please note that we may ask you to verify your identity before
        responding to such requests. Email your request to correct, amend, limit
        the use of, or delete your data to our data protection officer at
        <Link
          to="mailto:privacypolicy@ixda.org"
          sx={{ variant: 'variants.link' }}
        >
          privacypolicy@ixda.org
        </Link>
        .
      </P>
      <H4>Service Providers</H4>
      <P>
        We may employ third party companies and individuals to facilitate our
        Service (“Service Providers”), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used.
      </P>
      <P>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </P>
      <H4>Analytics</H4>
      <P>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </P>
      <H4>Google Analytics</H4>
      <P>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualize and
        personalize the ads of its own advertising network. This information is
        only processed in a way which does not identify anyone. We do not make,
        and do not allow Google to make, any attempt to find out the identities
        of those visiting our website.
      </P>
      <P>
        You can opt-out of having made your activity on the Service available to
        Google Analytics by installing the Google Analytics opt-out browser add-
        on. The add-on prevents the Google Analytics JavaScript (ga.js,
        analytics.js, and dc.js) from sharing information with Google Analytics
        about visits activity.
      </P>
      <P>
        For more information on the privacy practices of Google, please visit
        the{' '}
        <Link
          to="https://policies.google.com/privacy?hl=en"
          target="_blank"
          sx={{ variant: 'variants.link' }}
        >
          Google Privacy &amp; Terms web page
        </Link>
        .
      </P>
      <H4>Links To Other Sites</H4>
      <P>
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party’s site. We strongly advise you to review the Privacy Policy
        of every site you visit.
      </P>
      <P>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </P>
      <H4>Children’s Privacy</H4>
      <P>
        Our Service does not address anyone under the age of 13 (“Children”).
      </P>
      <P>
        We do not knowingly collect personally identifiable information from
        anyone under the age of 13. If you are a parent or guardian and you are
        aware that your Children has provided us with Personal Data, please
        contact us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </P>
      <H3>Changes To This Privacy Policy</H3>
      <P>
        This version of the Privacy Policy went into effect on May 28, 2018. We
        may update our Privacy Policy from time to time. We will notify you of
        any changes by posting the new Privacy Policy on this page.
      </P>
      <P>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the “effective date”
        at the top of this Privacy Policy.
      </P>
      <P>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </P>
      <H3>Contact Us</H3>
      <P>
        If you have any questions about this Privacy Policy, please contact us:
        <Link
          to="mailto:privacypolicy@ixda.org"
          sx={{ variant: 'variants.link' }}
        >
          privacypolicy@ixda.org
        </Link>
      </P>
    </PrivacyPolicyLayout>
  );
};

export default PrivacyPolicyPage;
